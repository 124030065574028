import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

export default () => {
  useEffect(() => {
    navigate('/?utm_source=events&utm_medium=webinar&utm_campaign=chinkee_tan');
  }, []);

  return <div />;
};
